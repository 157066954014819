module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sto Colombia","short_name":"Sto Colombia","description":"Somos una empresa Alemana líder mundial en sistemas para fachadas con aislamiento térmico. Desarrollamos soluciones que se adaptan a las necesidades de arquitectos, operarios, clientes y promotores.","lang":"es","start_url":"/","icon":"src/images/logos/Logo-Sto-Banderita.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"85d5837a31d0fa11a5f3755de307e565"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
