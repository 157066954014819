// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barrera-aire-humedad-js": () => import("./../../../src/pages/barrera-aire-humedad.js" /* webpackChunkName: "component---src-pages-barrera-aire-humedad-js" */),
  "component---src-pages-centro-de-documentos-js": () => import("./../../../src/pages/centro-de-documentos.js" /* webpackChunkName: "component---src-pages-centro-de-documentos-js" */),
  "component---src-pages-compromiso-de-marca-js": () => import("./../../../src/pages/compromiso-de-marca.js" /* webpackChunkName: "component---src-pages-compromiso-de-marca-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-eifs-js": () => import("./../../../src/pages/eifs.js" /* webpackChunkName: "component---src-pages-eifs-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nueva-construccion-js": () => import("./../../../src/pages/nueva-construccion.js" /* webpackChunkName: "component---src-pages-nueva-construccion-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-restauracion-js": () => import("./../../../src/pages/restauracion.js" /* webpackChunkName: "component---src-pages-restauracion-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */)
}

